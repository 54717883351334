<template>
  <div class="timer-holder ml-40">
    <svg width="100" height="100">
      <circle
        :style="{ strokeDashoffset: 0 }"
        class="circle-bg"
        cx="50"
        cy="50"
        r="16"
      />
      <circle
        :style="{ strokeDashoffset: timer }"
        class="circle"
        cx="50"
        cy="50"
        r="16"
      />
    </svg>
    <span class="timer">{{ timer }}</span>
  </div>
</template>
<script>
export default {
    data(){
        return{
            timer:100
        }
    },
    props:{
        timer:""
    }
}
</script>