<template>
  <div class="modal-wrapper">
    <div class="modal-container alert-modal">
      <div class="modal-header">{{ title }}</div>
      <div class="modal-body">
        <span class="material-symbols-outlined">close</span>
        <p>{{ text }}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-filled-blue m-auto" @click="closeModal" type="button">
          KAPAT
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCloseModal: false,
    };
  },
  props: {
    transactionId: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", this.isCloseModal);
    },
  },
};
</script>