function vkn(kno) {
  kno = kno.toString()
  if (kno.length === 10) {
    let v = []
    let lastDigit = Number(kno.charAt(9))
    for (let i = 0; i < 9; i++) {
      let tmp = (Number(kno.charAt(i)) + (9 - i)) % 10
      v[i] = (tmp * 2 ** (9 - i)) % 9
      if (tmp !== 0 && v[i] === 0) v[i] = 9
    }
    let sum = v.reduce((a, b) => a + b, 0) % 10
    return (10 - (sum % 10)) % 10 === lastDigit
  }
  return false
}
function tckn(tckn) {
  tckn = tckn.toString();

  if (tckn.substr(0, 1) === "0") return false;

  if (tckn.length !== 11) return false;

  const firstTen = tckn.substr(0, 10);

  let firstTenTotal, single, couple;

  firstTenTotal = single = couple = 0;

  let i, j;

  for (i = j = 0; i < 9; ++i) {
    j = parseInt(firstTen[i], 10);
    if (i & 1) couple += j;
    else single += j;
    firstTenTotal += j;
  }

  if ((single * 7 - couple) % 10 !== parseInt(tckn.substr(-2, 1), 10))
    return false;

  firstTenTotal += parseInt(firstTen[9], 10);

  if (firstTenTotal % 10 !== parseInt(tckn.substr(-1), 10)) return false;

  return true;
}
function formatCurrency(value) {
  if (value) {
    return parseFloat(value).toLocaleString('tr-TR', {
      style: 'currency',
      currency: 'TRY',
    })
  }
  else {
    return "0.00"
  }
}
function moneyTotext(para) {
  const birler = ['', 'BİR', 'İKİ', 'ÜÇ', 'DÖRT', 'BEŞ', 'ALTI', 'YEDİ', 'SEKİZ', 'DOKUZ'];
  const onlar = ['', 'ON', 'YİRMİ', 'OTUZ', 'KIRK', 'ELLİ', 'ALTMIŞ', 'YETMİŞ', 'SEKSEN', 'DOKSAN'];
  const birimler = ['', 'BİN', 'MİLYON', 'MİLYAR', 'TRİLYON', 'KATRİLYON'];

  if (para === 0) {
      return 'SIFIR';
  }

  let yazi = '';

  // tam sayı ve ondalık bölümleri ayırma
  let tamSayi = Math.floor(para);
  let ondalik = Math.round((para - tamSayi) * 100);

  // tam sayı kısmını yazıya çevirme
  let birimIndex = 0;
  while (tamSayi > 0) {
      let grup = tamSayi % 1000;
      tamSayi = Math.floor(tamSayi / 1000);

      if (grup > 0) {
          let yaziGrup = '';

          if (grup >= 100) {
              yaziGrup += birler[Math.floor(grup / 100)] == "BİR" ? "YÜZ" : birler[Math.floor(grup / 100)] + "YÜZ";
              grup %= 100;
          }

          if (grup >= 10) {
              yaziGrup += onlar[Math.floor(grup / 10)] + '';
              grup %= 10;
          }

          if (grup > 0) {
              yaziGrup += birler[grup] + '';
          }

          if (birimler[birimIndex] == "BİN") {
              if (yaziGrup == "BİR") {
                  yazi = birimler[birimIndex] + yazi;
              }
              else {
                  yazi = yaziGrup + birimler[birimIndex] + yazi;
              }
          }
          else {
              yazi = yaziGrup + birimler[birimIndex] + yazi;
          }
      }

      birimIndex++;
  }
  yazi += "TÜRKLİRASI"

  // ondalık kısmını yazıya çevirme
  if (ondalik > 0) {
      
      // if (yazi !== '') {
      //     yazi += '.';
      // }
      
      if (ondalik >= 10) {
          if(ondalik.toString().charAt(1) > 0){
              yazi += ondalik = onlar[Math.floor(ondalik / 10)] + birler[parseInt(ondalik.toString().charAt(1))]
          }
          else{
              yazi += ondalik = onlar[Math.floor(ondalik / 10)]
          }
      }
     
      yazi += "KURUŞ"

  }
  // sonucu döndürme
  return yazi;
}


function convertToDateToEn(date){
  date = new Date(`${date.slice(6,10)}-${date.slice(3,5)}-${date.slice(0,2)}`)
  return date
}


export { tckn, vkn, formatCurrency, moneyTotext, convertToDateToEn }