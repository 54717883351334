<template>
  <div class="modal-wrapper">
    <div class="modal-container">
      <form @submit.prevent="collect()">
        <div class="modal-header">TAHSİLAT İŞLEMLERİ</div>
        <div class="modal-body">
          <Table
            :thead="thead"
            :data="collectDataFixed"
            :exportOptinal="false"
            :search="false"
            :excel="false"
            :searchBox="false"
            tableTitle="TAHSİLAT LİSTESİ"
          >
            <template v-slot:cell-cancel="{ row: data }">
              <input
                class="form-check-input cancel-collect"
                type="checkbox"
                :value="data.cancel"
              />
            </template>
            <template v-slot:cell-salesNo="{ row: data }">
              {{ data.salesNo }}
            </template>
            <template v-slot:cell-fullname="{ row: data }">
              {{ data.fullname }}
            </template>
            <template v-slot:cell-amount="{ row: data }">
              {{ this.$formatCurrency(data.amount.value) }}
            </template>
            <template v-slot:cell-collectDate="{ row: data }">
              <VueDatePicker
                input-class-name="datepicker collect-date"
                v-model="data.collectDate"
                text-input
                locale="tr"
                :enable-time-picker="false"
                cancelText="İPTAL"
                selectText="TARİH SEÇ"
                auto-apply
                format="dd.MM.yyyy"
                v-mask="dateMask"
              />
            </template>
            <template v-slot:cell-lastCollectStatus="{ row: data }">
              <select class="form-select status-select">
                <option value="ÖDENDİ">ÖDENDİ</option>
                <option value="EKSİK ÖDENDİ">EKSİK ÖDENDİ</option>
              </select>
            </template>
            <template v-slot:cell-paymentChannel="{ row: data }">
              <select class="form-select channel-select">
                <option value="HAVALE">HAVALE</option>
                <option value="AKBANK">AKBANK</option>
                <option value="KREDİ KARTI">KREDİ KARTI</option>
              </select>
            </template>
          </Table>
        </div>
        <div class="modal-footer">
          <button class="btn btn-empty" @click="modalClose" type="button">
            İPTAL ET
          </button>
          <button class="btn btn-filled-blue" type="submit" :disabled="loading">
            TAHSİL ET
            <span
              class="material-symbols-outlined ml-10 spinner"
              v-if="loading"
            >
              progress_activity
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      collectChecked: [],
      channel: "HAVALE",
      status: "ÖDENDİ",
      data: [],
      detailData: [],
      collectDataFixed: [],
      date: "",
      loading: false,
      dateMask: {
        mask: "##.##.####",
        tokens: {
          "#": { pattern: /[0-9.]/ },
        },
      },
    };
  },
  props: {
    collectData: {
      type: Array,
    },
  },
  created() {
    this.collectData.forEach((item) => {
      this.collectDataFixed.push({
        cancel: item.id,
        installmentId: item.installmentId,
        salesNo: "",
        fullname: item.fullname,
        amount: item.monthlyInstallment,
        collectDate: "",
        lastCollectStatus: "",
        paymentChannel: "",
      });
    });
  },
  methods: {
    modalClose() {
      this.$emit("closeModal", this.isCloseModal);
    },

    collect() {
      let checkboxes = document.querySelectorAll(".cancel-collect");
      this.collectChecked = [];
      this.collectDataFixed.forEach((item) => {
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            let channel =
              checkbox.parentElement.parentElement.querySelector(
                ".channel-select"
              );
            let status =
              checkbox.parentElement.parentElement.querySelector(
                ".status-select"
              );
            let collectDate =
              checkbox.parentElement.parentElement.querySelector(
                ".collect-date"
              );
            if (checkbox.value == item.cancel) {
              this.collectChecked.push({
                id: item.installmentId,
                transactionId: item.cancel,
                paymentMethod: channel.value,
                status: status.value,
                paidDate: collectDate.value.replaceAll("-", "."),
              });
            }
          }
        });
      });

      if (this.collectChecked.length > 0) {
        this.loading = true;
        this.$axios
          .patch("transaction/installment", this.collectChecked)
          .then(() => {
            this.$axios.get("transaction/collection").then((response) => {
              this.loading = false;
              response.data.filter((i) => {
                for (let installment of i.installments.installmentTable) {
                  this.detailData.push({
                    id: i._id,
                    companyDealer:
                      i.user.company.companyName + " " + i.user.dealer.title,
                    tckn: i.tckn,
                    fullname: i.name + " " + i.surname,
                    monthlyInstallment: this.$formatCurrency(
                      installment.installmentAmount.$numberDecimal
                    ),
                    termTime: i.termTime,
                    remainingInstallment: i.remainingInstallmentCount
                      ? i.remainingInstallmentCount
                      : "Tamamlandı",
                    remainingAmount: i.remainingInstallmentTL
                      ? i.remainingInstallmentTL
                      : "Tamamlandı",
                    installmentExpDate:
                      i.installments.installmentTable[
                        i.installments.installmentTable.length - 1
                      ].installmentDate,
                    nextInstallment: i.nextPayment
                      ? i.nextPayment.installmentDate
                      : "Tamamlandı",
                    lastPaymentStatus: i.lastPayment
                      ? i.lastPayment.installmentStatus
                      : "",
                    salesNo: "1",
                    smsStatus: "",
                  });
                }

                this.detailData.push({
                  id: i._id,
                });

                this.data.push({
                  id: i._id,
                  companyDealer:
                    i.user.company.companyName + " " + i.user.dealer.title,
                  tckn: i.tckn,
                  fullname: i.name + " " + i.surname,
                  termTime: i.termTime,
                  monthlyInstallment: i.nextPayment
                    ? {
                        value: i.nextPayment.installmentAmount.$numberDecimal,
                        type: "currency",
                      }
                    : "TAMAMLANDI",
                  paymentDate: new Date(i.paymentDate).toLocaleDateString(
                    "tr-TR"
                  ),
                  remainingInstallment: i.remainingInstallmentCount
                    ? i.remainingInstallmentCount
                    : "TAMAMLANDI",
                  remainingAmount: i.remainingInstallmentTL
                    ? {
                        value: i.remainingInstallmentTL,
                        type: "currency",
                      }
                    : "TAMAMLANDI",
                  installmentExpDate: this.$convertToDateToEn(
                    i.installments.installmentTable[
                      i.installments.installmentTable.length - 1
                    ].installmentDate
                  ),
                  lastPaymentStatus: i.lastPayment
                    ? i.lastPayment.installmentStatus
                    : "",
                  installmentId: i.nextPayment
                    ? i.nextPayment._id
                    : "TAMAMLANDI",
                  lastPaymentMethod: i.lastPayment
                    ? i.lastPayment.paymentMethod
                    : "",
                  smsStatus: "",
                  nextInstallment: i.nextPayment
                    ? this.$convertToDateToEn(i.nextPayment.installmentDate)
                    : "TAMAMLANDI",
                  salesNo: "1",
                  overAllStatus: i.overAllStatus,
                  transactionId: i.nextPayment ? i.nextPayment._id : "",
                  installments: i.installments.installmentTable,
                });
              });
            });
            this.$emit("collectedData", this.data);
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
          });
      }
    },
  },
  setup() {
    const thead = [
      {
        label: "İPTAL",
        value: "cancel",
        searchable: false,
        exports: false,
      },
      {
        label: "SATIŞ NO",
        value: "salesNo",
        searchable: false,
        exports: false,
      },
      {
        label: "AD SOYAD",
        value: "fullname",
        searchable: false,
        exports: false,
      },
      {
        label: "TUTAR",
        value: "amount",
        searchable: false,
        exports: false,
      },
      {
        label: "TAHSİLAT TARİHİ",
        value: "collectDate",
        searchable: false,
        exports: false,
      },
      {
        label: "SON TAHSİLAT DURUMU",
        value: "lastCollectStatus",
        searchable: false,
        exports: false,
      },
      {
        label: "ÖDEME DURUMU",
        value: "paymentChannel",
        searchable: false,
        exports: false,
      },
    ];
    return {
      thead,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  max-width: 900px;
  max-height: 1000px;
}
.modal-body {
  max-height: 700px;
  overflow-y: auto;
}
</style>