<template>
  <VueDatePicker
    input-class-name="form-control datepicker"
    v-model="search"
    :placeholder="placeholder"
    locale="tr"
    :enable-time-picker="false"
    auto-apply
    cancelText="İPTAL"
    selectText="TARİH SEÇ"
    format="dd.MM.yyyy"
    @update:model-value="onDateChange"
    @cleared="this.search = ''"
    v-if="searchType == 'date'"
    v-mask="dateMask"
  />
  <input
    type="text"
    v-model="search"
    class="form-control"
    :placeholder="placeholder"
    @input="onSearchInput()"
    v-uppercase="(value) => (search = value)"
    v-else
  />
</template>
<script>
export default {
  data() {
    return {
      search: "",
      key: "",
      dateMask: {
        mask: "##.##.####",
        tokens: {
          "#": { pattern: /[0-9.]/ },
        },
      },
    };
  },
  props: {
    data: Array,
    placeholder: String,
    cls: String,
    searchKey: String,
    searchType: String,
  },
  methods: {
    onSearchInput() {
      this.$emit("search", this.filtredData);
    },
    onDateChange(){
      this.$emit("search", this.filtredData);
      this.search = this.$convertToDateToEn(new Date(this.search).toLocaleDateString("tr-TR"));
    }
  },
  computed: {
    filtredData() {
      if (this.search) {
        let searchTerm = String(this.search).toLocaleLowerCase("tr-TR");
        let newArr = [];
        
        if (this.searchKey == "all") {
          this.data.filter((item) => {
            Object.entries(item).some((i) => {
              return String(i[1])
                .toLocaleLowerCase("tr-TR")
                .includes(searchTerm)
                ? newArr.push(item)
                : "";
            });
          });
        } else {
          this.data.filter((item) => {
            if (typeof item[this.searchKey] == "string") {
              return String(item[this.searchKey])
                .toLocaleLowerCase("tr-TR")
                .includes(searchTerm)
                ? newArr.push(item)
                : "";
            } else if (typeof item[this.searchKey] == "number") {
              return String(item[this.searchKey])
                .toLocaleLowerCase("tr-TR")
                .includes(searchTerm)
                ? newArr.push(item)
                : "";
            } else if (
              typeof item[this.searchKey] == "object" &&
              item[this.searchKey].type == "currency"
            ) {
              return this.$formatCurrency(item[this.searchKey].value).includes(
                searchTerm
              )
                ? newArr.push(item)
                : "";
            } else{
              let convertedItem = new Date(item[this.searchKey]).toLocaleDateString("tr-TR")
              let convertedKey = new Date(this.search).toLocaleDateString("tr-TR")
              return convertedItem == convertedKey
                ? newArr.push(item)
                : "";
            }
          });
        }
        return newArr;
      } else {
        return this.data;
      }
    },
  },
};
</script>