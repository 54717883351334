<template>
  <div class="modal-wrapper">
    <div class="modal-container alert-modal show-file-modal">
      <div class="modal-header">{{ fileName }}</div>
      <div class="modal-body">
        <embed
          :src="$env.VUE_API_ROOT + 'uploads/' + transactionId + '/' + fileName"
          width="100%"
          height="  600px;"
        />
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-filled-blue m-auto"
          @click="closeModal"
          type="button"
        >
          KAPAT
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCloseModal: false,
    };
  },
  props: {
    transactionId: {
      type: String,
    },
    transactionId: {
      type: String,
    },
    fileName: {
      type: String,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", this.isCloseModal);
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-wrapper .modal-container {
  max-width: 700px;
  max-height: 800px;
  .modal-body {
    max-height: 500px;
    overflow-y: auto;
  }
} 
</style>
