<template>
  <th @click="sorted(value)">
    <div class="d-flex align-items-center justify-content-center">
      {{ text }}
      <span class="material-symbols-outlined sort-arrow" :class="value">
        arrow_upward
      </span>
    </div>
  </th>
</template>

<script>
export default {
  props: {
    data: Array,
    value: String,
    text: String,
  },
  methods: {
    sorted(value) {
      this.$emit("sortColumn", this.sort(value));
    },

    sort(value) {
      let current = document.querySelector(`.sort-arrow.${value}`);
      current.classList.toggle("active");
      document
        .querySelectorAll(`.sort-arrow:not(.${value})`)
        .forEach((arrow) => arrow.classList.remove("active"));
      if (current.classList.contains("active")) {
        this.data.sort((a, b) => {
          if (typeof a[value] === "string") {
            return a[value].localeCompare(b[value], "tr", {
              sensitivity: "base",
            });
          }
          // Sayılar ve tarihler için özel karşılaştırma
          else if (typeof a[value] === "number") {
            return a[value] - b[value];
          }
          else if(typeof a[value] === "object" && a[value].type == "currency"){
            return a[value].value - b[value].value;
          }
          // Diğer durumlar için varsayılan karşılaştırma
          else {
            return a[value] - b[value];
          }
        });
      } else {
        this.data.reverse();
      }
      return this.data;
    },
  },
};
</script>
<style scoped>
.sort-arrow.active {
  transform: rotate(180deg);
}
.sort-arrow {
  font-size: 1rem;
  margin-left: 15px;
}
</style>