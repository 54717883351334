import axios from 'axios';
import { jwtDecrypt, tokenAllive } from '../jwtHelper.js';

const state = () => ({
    isLoggedIn: false,
    authData: {
        access_token: "",
        refresh_token: "",
        user_id: "",
        role: "",
        dealerStatus: true,
        companyID: "",
        user_name: "",
    }
})

const getters = {
    getIsLoggedIn(state) {
        return state.isLoggedIn;
    },
    getUserId(state) {
        return state.user_id
    },
    getUserRole(state) {
        return state.authData.role
    }

};

const actions = {
    loginReq({ commit }, payload) {
        commit('setIsLoggedIn', true)
        commit('setAuthData', payload.tokens)
    },

};

const mutations = {
    setIsLoggedIn(state, payload) {
        state.isLoggedIn = payload
    },
    async setAuthData(state, data) {
        const decryptedAuth = jwtDecrypt(data.accessToken)
        const newAuthData = {
            access_token: data.accessToken,
            refresh_token: data.refresh_token,
            user_id: decryptedAuth.id,
            role: decryptedAuth.role,
            dealerStatus: decryptedAuth.dealerStatus,
        }
        if (decryptedAuth.role != "customer") {
            axios
                .get(`/user/${decryptedAuth.id}`)
                .then((resp) => {
                    newAuthData.companyID = resp.data.company ? resp.data.company._id : ""
                    newAuthData.user_name = `${resp.data.firstname} ${resp.data.lastname}`
                });
        }
        else{
            axios.get("customer/profile").then((response) => {
                newAuthData.user_name = `${response.data.name} ${response.data.surname}`
            })
        }
        state.authData = newAuthData
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}