const env = {
    dev: {
        VUE_API_ROOT: "http://35.173.47.163:3000/",
        VUE_APP_BASE_URL: "http://35.173.47.163:3000/api/"
    },
    staging: {
        VUE_API_ROOT: "https://peyderpay-api.sysline.com:443/",
        VUE_APP_BASE_URL: "https://peyderpay-api.sysline.com:443/api/"
    },
    production: {
        VUE_API_ROOT: "https://api.peyderpay.com.tr/",
        VUE_APP_BASE_URL: "https://api.peyderpay.com.tr/api/"
    }  
}

export default env.production