<template>
  <div class="table-wrapper">
    <div class="table-header">
      <SearchBox
        :searchKey="'all'"
        :data="data"
        :thead="thead"
        @search="onSearch"
        :placeholder="'Ara'"
        v-if="search"
      />
      <vue-excel-xlsx
        :data="
          excelData.length > 0
            ? excelData
            : manipulatedData
        "
        :thead="thead"
        :detailHead="detailHead"
        :detailData="excelDetailData.length > 0 ? excelDetailData : detailData"
        :fileName="tableTitle"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
        :exportOptinal="exportOptinal"
        v-if="excel"
      />
      
      <slot name="button"></slot>
    </div>
    <nav class="paginate">
      GÖSTER:
      <ul class="pagination">
        <li class="page-item">
          <button
            class="page-link"
            v-if="pageNumber > showDataCount"
            @click="setPrev"
            type="button"
          >
            <span class="material-symbols-outlined"> chevron_left </span>
          </button>
        </li>
        <li>
          {{
            searched
              ? pageNumber > manipulatedDataLength
                ? manipulatedDataLength
                : pageNumber
              : pageNumber > data.length
              ? data.length
              : pageNumber
          }}
        </li>
        <li>
          <button
            class="page-link"
            type="button"
            v-if="
              (manipulatedDataLength > pageNumber)
            "
            @click="setNext"
          >
            <span class="material-symbols-outlined"> chevron_right </span>
          </button>
        </li>
      </ul>
    </nav>
    <div class="table-container">
      <h4 class="table-title">{{ tableTitle }}</h4>

      <table class="table">
        <thead>
          <tr>
            <th v-if="excel">HEPSİNİ SEÇ</th>
            <template v-for="item in thead" :key="item">
              <th v-if="item.value == 'icon'">
                <span class="material-symbols-outlined">
                  {{ item.label }}
                </span>
              </th>
              <SortTable
                @sortColumn="onSort"
                :data="data"
                :value="item.value"
                :text="item.label"
                v-else-if="item.sortable"
              />
              <th v-else>{{ item.label }}</th>
            </template>
          </tr>
          <tr v-if="searchBox">
            <th v-if="excel">
              <input
                class="form-check-input excel-check-all"
                type="checkbox"
                @change="excelCheckAll($event)"
              />
            </th>
            <template v-for="searchBox in thead" :key="searchBox">
              <th v-if="searchBox.value == 'check'">
                <div class="form-check ml-10">
                  <input
                    class="form-check-input check-all"
                    type="checkbox"
                    @change="checkAll($event)"
                  />
                </div>
              </th>
              <th v-else-if="searchBox.searchable">
                <SearchBox
                  :data="data"
                  @search="onSearch"
                  :searchKey="searchBox.value"
                  :placeholder="searchBox.label"
                  :searchType="searchBox.type"
                />
              </th>
              <th v-else></th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="data == ''">
            <tr>
              <td>GÖSTERİLECEK İŞLEM YOK.</td>
            </tr></template
          >
          <template v-else-if="searched && manipulatedDataLength.length == 0">
            <tr>
              <td>EŞLEŞEN İŞLEM YOK.</td>
            </tr>
          </template>
          <template v-if="monthFiltred">
            <template v-if="filtredDataAsMont.length > 0">
              <template v-for="(item, i) in filtredDataAsMont" :key="i">
                <tr
                  v-if="i < pageNumber && i >= pageNumber - showDataCount"
                  :class="
                    lineFade && item.overAllStatus != 'ÖDEME GERÇEKLEŞTİ'
                      ? 'line-fade'
                      : ''
                  "
                >
                  <td v-if="excel">
                    <input
                      type="checkbox"
                      class="form-check-input excel-check"
                      :value="item.id"
                      @change="addExcell(item.id)"
                    />
                  </td>
                  <template v-for="(cell, i) in thead" :key="i">
                    <td class="text-center">
                      <slot :name="`cell-${cell.value}`" :row="item">
                        {{ item[prop] }}
                      </slot>
                    </td>
                  </template>
                </tr>
              </template>
            </template>
            <template v-else>
              <tr>
                <td>EŞLEŞEN İŞLEM YOK.</td>
              </tr>
            </template>
          </template>
          <template v-for="(item, i) in manipulatedData" :key="i" v-else>
            <tr
              v-if="i < pageNumber && i >= pageNumber - showDataCount"
              :class="
                lineFade && item.overAllStatus != 'ÖDEME GERÇEKLEŞTİ'
                  ? 'line-fade'
                  : ''
              "
            >
              <td v-if="excel">
                <input
                  type="checkbox"
                  class="form-check-input excel-check"
                  :value="item.id"
                  @change="addExcell(item.id)"
                />
              </td>
              <template v-for="(cell, i) in thead" :key="i">
                <td class="text-center">
                  <slot :name="`cell-${cell.value}`" :row="item">
                    {{ item[prop] }}
                  </slot>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
      <template v-if="preloader">
        <div class="table-preloader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </template>
    </div>
    <button
      class="btn btn-empty-grey-600 uncheckSelected"
      @click="uncheckSelected"
      v-if="excel"
    >
      <span class="material-symbols-outlined"> delete </span>
      SEÇİMİ TEMİZLE
    </button>
  </div>
  {{ watched }}
</template>
<script>
import SearchBox from "./SearchBox.vue";
import SortTable from "./SortTable.vue";
import VueExcelXlsx from "./VueExcelXlsx.vue";

export default {
  name: "Table",
  components: {
    SearchBox,
    VueExcelXlsx,
    SortTable,
  },
  data() {
    return {
      searchKey: "",
      tableData: [],
      sortedData: [],
      excelData: [],
      excelDetailData: [],
      searched: false,
      showColletBtn: false,
      showDataCount: 20,
      pageNumber: 20,
      manipulatedData: [],
      filtredDataAsMont: [],
      monthFiltred: false,
      prop:[],
      dateMask: {
        mask: "##.####",
        tokens: {
          "#": { pattern: /[0-9.]/ },
        },
      },
      manipulatedDataLength:""
    };
  },
  props: {
    data: Array,
    thead: Array,
    detailHead: Array,
    detailData: Array,
    search: Boolean,
    excel: Boolean,
    searchBox: Boolean,
    tableTitle: String,
    exportOptinal: Boolean,
    lineFade: Boolean,
    monthFilter: Boolean,
    preloader: {
      type: Boolean,
      default: true
    },
  },
  created() {
    this.pageNumber = this.showDataCount;
    this.searched = false;
  },

  watch: {
    pageNumber: function () {
      this.setData();
    },
  },
  computed: {
    watched() {
      this.setData();
    },
  },
  methods: {
    uncheckSelected() {
      let checkboxes = document.querySelectorAll(".excel-check");
      let allCheckBox = document.querySelector(".excel-check-all");
      checkboxes.forEach((checkbox) => (checkbox.checked = false));
      allCheckBox.checked = false;
    },
    setData() {
      if (!this.searched) {
        this.manipulatedData = this.data;
        this.manipulatedDataLength = this.data.length
      }
      setTimeout(() => {
        document.querySelector(".table-preloader")
          ? document.querySelector(".table-preloader").remove()
          : "";
      }, 2000);
    },
    addExcell(value) {
      let row = this.manipulatedData.find((item) => item.id == value);
      const index = this.excelData.findIndex(
        (containsItem) => containsItem.id === value
      );

      if (index !== -1) {
        this.excelData.splice(index, 1);
      } else {
        this.excelData.push(row);
      }

      let checkboxes = document.querySelectorAll(".excel-check");
      let excelAllCheck = document.querySelector(".excel-check-all");

      this.excelDetailData = [];

      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          if (this.detailData) {
            this.detailData.filter((item) => {
              if (item.id == checkbox.value) {
                this.excelDetailData.push(item);
              }
            });
          }
        }
        checkbox.addEventListener("change", () => {
          let checkAllCheckbox = Array.prototype.every.call(
            checkboxes,
            (chk) => chk.checked
          );

          if (checkAllCheckbox) {
            excelAllCheck.checked = true;
          } else {
            excelAllCheck.checked = false;
          }
        });
      });
    },
    excelCheckAll(e) {
      let checkboxes = document.querySelectorAll(".excel-check");
      checkboxes.forEach((checkbox) => {
        if (!e.target.checked) {
          this.excelData = [];
          checkbox.checked = false;
        } else {
          if (!checkbox.checked) {
            checkbox.checked = true;
            this.addExcell(checkbox.value);
          }
        }
      });
    },
    onSearch(value) {
      this.pageNumber = this.showDataCount;
      value.length == this.data.length
        ? (this.searched = false)
        : (this.searched = true);
      this.manipulatedData = value;
      this.manipulatedDataLength = value.length
    },
    onSort(value) {
      this.manipulatedData = value;
      this.manipulatedDataLength = value.length
    },
    setPrev() {
      this.pageNumber -= this.showDataCount;
    },
    setNext() {
      this.pageNumber += this.showDataCount;
    },

    checkAll(e) {
      let table = document.querySelector(".table-wrapper");
      let checkboxes = table.querySelectorAll(".channel-checkbox");
      let assignCheckboxes = table.querySelectorAll(".assign-checkbox");
      checkboxes.forEach((checkbox) => {
        if (e.target.checked) {
          checkbox.checked ? "" : checkbox.click();
        } else {
          checkbox.checked ? checkbox.click() : "";
        }
      });
      assignCheckboxes.forEach((checkbox) => {
        if (e.target.checked) {
          checkbox.checked ? "" : checkbox.click();
        } else {
          checkbox.checked ? checkbox.click() : "";
        }
      });
    },
  },
};
</script>
<style scoped>
.removeElem {
  display: none;
}
.table-wrapper {
  position: relative;
}
</style>