import { createStore } from 'vuex'
import authModule from './module/auth'
import userModal from './module/user'

const  store = createStore({
    modules: {
        auth : authModule,
        user : userModal 
    }
})

export default store;