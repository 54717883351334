import { createWebHistory, createRouter } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    meta: {
      title: "ANASAYFA",
      icon: "home",
      requiredAuthorization: true,
      roles: ["admin", "store", "dealer"],
    },
    component: () =>
      import(/* webpackChunkName: "layout" */ "../components/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          title: "",
          icon: "home",
          requiredAuthorization: true,
          roles: ["admin", "store", "dealer"],
        },
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
      },
      {
        path: "/customer-query",
        name: "CustomerQuery",
        meta: {
          title: "YENİ MÜŞTERİ SORGU",
          icon: "person_search",
          requiredAuthorization: true,
          roles: ["admin", "store", "dealer"],
        },
        component: () =>
          import(
            /* webpackChunkName: "customerquery" */ "../views/CustomerQuery.vue"
          ),
      },
      {
        path: "customers",
        redirect: "/customers/list",
        meta: {
          title: "MÜŞTERİLER",
          icon: "group",
          requiredAuthorization: true,
          roles: ["admin",],
        },
        props: true,
        component: () =>
          import(/* webpackChunkName: "customers" */ "../views/Customers.vue"),
        children: [
          {
            path: "list",
            name: "CustomerList",
            meta: {
              title: "LİSTE",
              icon: "group",
              requiredAuthorization: true,
              roles: ["admin",],
            },
            props: true,
            component: () =>
              import(/* webpackChunkName: "customers" */ "../views/CustomerList.vue"),
          },
          {
            path: ":id",
            redirect: "detail",
            props: true,
            meta: {
              title: "MÜŞTERİ DETAY",
              icon: "person",
              requiredAuthorization: true,
              roles: ["admin",],
            },
            component: () =>
              import(
                /* webpackChunkName: "customerdetail" */ "../views/Customer.vue"
              ),
            children: [
              {
                path: "detail",
                name: "CustomerDetail",
                props: true,
                meta: {
                  title: "DETAY",
                  icon: "person",
                  requiredAuthorization: true,
                  roles: ["admin",],
                },
                component: () =>
                  import(
                /* webpackChunkName: "customerdetailInfo" */ "../views/CustomerInfo.vue"
                  ),
              },
              {
                path: "files",
                name: "CustomerFiles",
                props: true,
                meta: {
                  title: "MÜŞTERİ EVRAKLARI",
                  icon: "person",
                  requiredAuthorization: true,
                  roles: ["admin",],
                },
                component: () =>
                  import(
                    /* webpackChunkName: "customerfiles" */ "../views/CustomerFiles.vue"
                  ),
              },
              {
                path: ":salesId",
                name: "CustomerSalesDetail",
                props: true,
                meta: {
                  title: "SATIŞ DETAY",
                  icon: "receipt_long",
                  requiredAuthorization: true,
                  roles: ["admin", "store", "dealer"],
                },
                component: () =>
                  import(
                    /* webpackChunkName: "salesdetail" */ "../views/SalesDetail.vue"
                  ),
              },
              {
                path: "customer-scaned-files/:salesId",
                name: "CustomerScanedFiles",
                props: true,
                meta: {
                  title: "MÜŞTERİ TARANAN EVRAKLAR",
                  icon: "receipt_long",
                  requiredAuthorization: true,
                  roles: ["admin", "store", "dealer"],
                },
                component: () =>
                  import(
                    /* webpackChunkName: "customerScanedFiles" */ "../views/CustomerScanedFiles.vue"
                  ),
              },
            ]
          },
        ]
      },
      {
        path: "sales-process",
        redirect: "/sales-process/queried-process/list",
        meta: {
          title: "SATIŞ İŞLEMLERİ",
          icon: "receipt_long",
          requiredAuthorization: true,
          roles: ["admin", "store", "dealer"],
        },
        component: () =>
          import(
            /* webpackChunkName: "salesprocess" */ "../views/SalesProcess.vue"
          ),
        children: [
          {
            path: "queried-process",
            redirect: "/sales-process/queried-process/list",
            meta: {
              title: "SORGULANAN İŞLEMLER",
              icon: "receipt_long",
              requiredAuthorization: true,
              roles: ["admin", "store", "dealer"],
            },
            component: () =>
              import(
                /* webpackChunkName: "queriedprocess" */ "../views/QueriedProcesses.vue"
              ),
            children: [
              {
                path: "list",
                name: "QueriedProcess",
                meta: {
                  title: "LİSTE",
                  icon: "receipt_long",
                  requiredAuthorization: true,
                  roles: ["admin", "store", "dealer"],
                },
                component: () =>
                  import(
                /* webpackChunkName: "queriedprocess" */ "../views/QueriedProcessesList.vue"
                  ),
              },
              {
                path: ":id",
                name: "QueriedProcessDetail",
                props: true,
                meta: {
                  title: "DETAY",
                  icon: "receipt_long",
                  requiredAuthorization: true,
                  roles: ["admin", "store", "dealer"],
                },
                component: () =>
                  import(
                    /* webpackChunkName: "queriedprocessdetail" */ "../views/QueriedProcess.vue"
                  ),
              },
            ]
          },
          {
            path: "complated-sales-process",
            redirect: "/sales-process/complated-sales-process/list",
            meta: {
              title: "SATIŞI TAMAMLANMIŞ İŞLEMLER",
              icon: "receipt_long",
              requiredAuthorization: true,
              roles: ["admin", "store", "dealer"],
            },
            component: () =>
              import(
                /* webpackChunkName: "complatedsalesprocess" */ "../views/ComplatedSalesProcess.vue"
              ),
            children: [
              {
                path: "list",
                name: "ComplatedSalesProcessList",
                meta: {
                  title: "LİSTE",
                  icon: "receipt_long",
                  requiredAuthorization: true,
                  roles: ["admin", "store", "dealer"],
                },
                component: () =>
                  import(
                /* webpackChunkName: "complatedsalesprocessList" */ "../views/ComplatedSalesProcessList.vue"
                  ),
              },
              {
                path: ":id",
                name: "ComplatedSalesProcessDetail",
                props: true,
                meta: {
                  title: "DETAY",
                  icon: "receipt_long",
                  requiredAuthorization: true,
                  roles: ["admin", "store", "dealer"],
                },
                component: () =>
                  import(
                    /* webpackChunkName: "complatedSalesProcessDetail" */ "../views/ComplatedSalesProcessDetail.vue"
                  ),
              },
              {
                path: ":id",
                name: "ComplatedFastScan",
                props: true,
                meta: {
                  title: "HIZLI TARAMA",
                  icon: "upload_file",
                  requiredAuthorization: true,
                  roles: ["admin", "store", "dealer"],
                },
                component: () =>
                  import(
                    /* webpackChunkName: "complatedsalesprocess" */ "../views/FastScan.vue"
                  ),
              },
            ]
          },

          {
            path: "canceled-sales",
            redirect: "/sales-process/canceled-sales/list",
            meta: {
              title: "İPTAL / İADE İŞLEMLER",
              icon: "receipt_long",
              requiredAuthorization: true,
              roles: ["admin", "store", "dealer"],
            },
            component: () =>
              import(
                /* webpackChunkName: "canceledsales" */ "../views/CanceledSales.vue"
              ),
            children: [
              {
                path: "list",
                name: "CanceledSalesList",
                meta: {
                  title: "LİSTE",
                  icon: "receipt_long",
                  requiredAuthorization: true,
                  roles: ["admin", "store", "dealer"],
                },
                component: () =>
                  import(
                      /* webpackChunkName: "canceledsales" */ "../views/CanceledSalesList.vue"
                  ),
              },
              {
                path: ":id",
                name: "CanceledFastScan",
                props: true,
                meta: {
                  title: "HIZLI TARAMA",
                  icon: "upload_file",
                  requiredAuthorization: true,
                  roles: ["admin", "store", "dealer"],
                },
                component: () =>
                  import(
                      /* webpackChunkName: "complatedsalesprocess" */ "../views/FastScan.vue"
                  ),
              },
            ]
          },
          {
            path: "assignment-sales-process",
            redirect: "/sales-process/assignment-sales-process/list",
            props: true,
            meta: {
              title: "SATIŞI TEMLİK EDİLECEK İŞLEMLER",
              icon: "receipt_long",
              requiredAuthorization: true,
              roles: ["admin", "store"],
            },
            component: () =>
              import(
                /* webpackChunkName: "assignmentsalesprocess" */ "../views/AssignmentSalesProcess.vue"
              ),
              children: [
                {
                  path: "list",
                  name: "AssignmentSalesProcess",
                  props: true,
                  meta: {
                    title: "LİSTE",
                    icon: "receipt_long",
                    requiredAuthorization: true,
                    roles: ["admin", "store"],
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "assignmentsalesprocess" */ "../views/AssignmentSalesProcessList.vue"
                    ),
                },
                {
                  path: ":id",
                  name: "AssignmentSalesProcessDetail",
                  props: true,
                  meta: {
                    title: "DETAY",
                    icon: "receipt_long",
                    requiredAuthorization: true,
                    roles: ["admin", "store"],
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "assignmentsalesprocessdetail" */ "../views/AssignmentSalesProcessDetail.vue"
                    ),
                },
              ]
          },        
          {
            path: "assigned-sales-process",
            redirect: "/sales-process/assigned-sales-process/list",
            props: true,
            meta: {
              title: "SATIŞI TEMLİK EDİLMİŞ İŞLEMLER",
              icon: "receipt_long",
              requiredAuthorization: true,
              roles: ["admin", "store"],
            },
            component: () =>
              import(
                /* webpackChunkName: "assignedsalesprocess" */ "../views/AssignedSalesProcess.vue"
              ),
              children: [
                {
                  path: "list",
                  name: "AssignedSalesProcess",
                  props: true,
                  meta: {
                    title: "LİSTE",
                    icon: "receipt_long",
                    requiredAuthorization: true,
                    roles: ["admin", "store"],
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "assignedsalesprocess" */ "../views/AssignedSalesProcessList.vue"
                    ),
                },
                {
                  path: ":id",
                  name: "AssignedSalesProcessDetail",
                  props: true,
                  meta: {
                    title: "DETAY",
                    icon: "receipt_long",
                    requiredAuthorization: true,
                    roles: ["admin", "store"],
                  },
                  component: () =>
                    import(
                      /* webpackChunkName: "assignedsalesprocessdetail" */ "../views/AssignedSalesProcessDetail.vue"
                    ),
                },
              ]
          },  
        ],
      },

      {
        path: "parameters",
        redirect: "operational-parameters",
        meta: {
          title: "PARAMETRELER",
          icon: "calculate",
          requiredAuthorization: true,
          roles: ["admin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "parameters" */ "../views/Parameters.vue"
          ),
        children: [
          {
            path: "operational-parameters",
            name: "OperationalParameters",
            meta: {
              title: "OPERASYONEL PARAMETRELER",
              icon: "calculate",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "operationalParameters" */ "../views/OperationalParameters.vue"
              ),
          },
          {
            path: "transaction",
            redirect: "/parameters/transaction/list",
            meta: {
              title: "İŞLEM FAİZ PARAMETRELERİ",
              icon: "calculate",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "transactionParameters" */ "../views/TransactionParameters.vue"
              ),
            children: [
              {
                path: "list",
                name: "TransactionParameterList",
                meta: {
                  title: "LİSTE",
                  icon: "calculate",
                  requiredAuthorization: true,
                  roles: ["admin"],
                },
                component: () =>
                  import(
                  /* webpackChunkName: "transactionParameterslist" */ "../views/TransactionParameterList.vue"
                  ),
              },
              {
                path: ":id",
                name: "TransactionParameterDetail",
                meta: {
                  title: "GÜNCELLE",
                  icon: "calculate",
                  requiredAuthorization: true,
                  roles: ["admin"],
                },
                component: () =>
                  import(
                      /* webpackChunkName: "transactionParameter" */ "../views/TransactionParameter.vue"
                  ),
              },
              {
                path: "update-all",
                name: "TransactionParameterUpdateAll",
                meta: {
                  title: "TOPLU GÜNCELLE",
                  icon: "calculate",
                  requiredAuthorization: true,
                  roles: ["admin"],
                },
                component: () =>
                  import(
                      /* webpackChunkName: "transactionParameter" */ "../views/TransactionParameterUpdateAll.vue"
                  ),
              },
              {
                path: "add",
                name: "AddTransactionParameter",
                meta: {
                  title: "EKLE",
                  icon: "calculate",
                  requiredAuthorization: true,
                  roles: ["admin"],
                },
                component: () =>
                  import(
                      /* webpackChunkName: "transactionParameter" */ "../views/TransactionParameter.vue"
                  ),
              },
            ]
          },
          {
            path: "product-category",
            redirect: "/parameters/product-category/list",
            meta: {
              title: "ÜRÜN KATEGORİ PARAMETRELERİ",
              icon: "calculate",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "productCategoryParameters" */ "../views/ProductCategoryParameters.vue"
              ),
            children: [
              {
                path: "list",
                name: "ProductCategoryParameterList",
                meta: {
                  title: "LİSTE",
                  icon: "calculate",
                  requiredAuthorization: true,
                  roles: ["admin"],
                },
                component: () =>
                  import(
                  /* webpackChunkName: "productCategoryParameterlist" */ "../views/ProductCategoryParameterList.vue"
                  ),
              },
              {
                path: ":id",
                name: "ProductCategoryParameterDetail",
                meta: {
                  title: "GÜNCELLE",
                  icon: "calculate",
                  requiredAuthorization: true,
                  roles: ["admin"],
                },
                component: () =>
                  import(
                        /* webpackChunkName: "productCategoryParameter" */ "../views/ProductCategoryParameter.vue"
                  ),
              },
              {
                path: "update-all",
                name: "ProductCategoryParameterUpdateAll",
                meta: {
                  title: "TOPLU GÜNCELLE",
                  icon: "calculate",
                  requiredAuthorization: true,
                  roles: ["admin"],
                },
                component: () =>
                  import(
                        /* webpackChunkName: "productCategoryParameterUpdateAll" */ "../views/ProductCategoryParameterUpdateAll.vue"
                  ),
              },
              {
                path: "add",
                name: "AddProductCategoryParameter",
                meta: {
                  title: "EKLE",
                  icon: "calculate",
                  requiredAuthorization: true,
                  roles: ["admin"],
                },
                component: () =>
                  import(
                        /* webpackChunkName: "productCategoryParameter" */ "../views/ProductCategoryParameter.vue"
                  ),
              },
            ]
          },


        ]
      },

      {
        path: "status-report",
        redirect: "/status-report/list",
        meta: {
          title: "DURUM RAPORU",
          icon: "find_in_page",
          requiredAuthorization: true,
          roles: ["admin", "store"],
        },
        component: () =>
          import(
            /* webpackChunkName: "statusreport" */ "../views/StatusReport.vue"
          ),
        children: [
          {
            path: "list",
            name: "StatusReportList",
            meta: {
              title: "LİSTE",
              icon: "find_in_page",
              requiredAuthorization: true,
              roles: ["admin", "store"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "statusreportlist" */ "../views/StatusReportList.vue"
              ),
          },
          {
            path: ":id",
            name: "StatusReportDetail",
            props: true,
            meta: {
              title: "DETAY",
              icon: "receipt_long",
              requiredAuthorization: true,
              roles: ["admin", "store", "dealer"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "salesdetail" */ "../views/SalesDetail.vue"
              ),
          },
        ]
      },
      {
        path: "collectings",
        redirect: "/collectings/list",
        meta: {
          title: "TAHSİLAT",
          icon: "account_balance_wallet",
          requiredAuthorization: true,
          roles: ["admin", "store"],
        },
        component: () =>
          import(
            /* webpackChunkName: "collecting" */ "../views/Collectings.vue"
          ),
        children: [
          {
            path: "list",
            name: "CollectingList",
            meta: {
              title: "LİSTE",
              icon: "account_balance_wallet",
              requiredAuthorization: true,
              roles: ["admin", "store"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "collecting" */ "../views/CollectingList.vue"
              ),
          },
          {
            path: ":id",
            name: "CollectingDetail",
            props: true,
            meta: {
              title: "DETAY",
              icon: "account_balance_wallet",
              requiredAuthorization: true,
              roles: ["admin", "store"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "collectingdetail" */ "../views/Collecting.vue"
              ),
          },
        ]
      },

      {
        path: "companies",
        redirect: "/companies/list",
        meta: {
          title: "MAĞAZA İŞLEMLERİ",
          icon: "store",
          requiredAuthorization: true,
          roles: ["admin"],
        },
        component: () =>
          import(
            /* webpackChunkName: "company-list" */ "../views/Companies.vue"
          ),
        children: [
          {
            path: "list",
            name: "CompanyList",
            meta: {
              title: "LİSTE",
              icon: "store",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "company-list" */ "../views/CompanyList.vue"
              ),
          },
          {
            path: "add",
            name: "Company",
            meta: {
              title: "EKLE",
              icon: "store",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "company" */ "../views/Company.vue"
              ),
          },
          {
            path: ":id",
            name: "CompanyDetail",
            meta: {
              title: "GÜNCELLE",
              icon: "store",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "add-company" */ "../views/Company.vue"
              ),
          },
        ]
      },
      {
        path: "dealers",
        redirect: "/dealers/list",
        meta: {
          title: "BAYİ İŞLEMLERİ",
          icon: "store",
          requiredAuthorization: true,
          roles: ["admin", "store"],
        },
        component: () =>
          import(
            /* webpackChunkName: "dealer" */ "../views/Dealers.vue"
          ),
        children: [
          {
            path: "list",
            name: "DealerList",
            meta: {
              title: "LİSTE",
              icon: "store",
              requiredAuthorization: true,
              roles: ["admin", "store"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "dealer" */ "../views/DealerList.vue"
              ),
          },
          {
            path: "add",
            name: "Dealer",
            meta: {
              title: "EKLE",
              icon: "store",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "dealer" */ "../views/Dealer.vue"
              ),
          },
          {
            path: ":id",
            name: "DealerDetail",
            meta: {
              title: "GÜNCELLE",
              icon: "store",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
                  /* webpackChunkName: "dealer" */ "../views/Dealer.vue"
              ),
          },
        ]
      },


      {
        path: "users",
        redirect: "/users/list",
        meta: {
          title: "KULLANICI LİSTESİ",
          icon: "contact_page",
          requiredAuthorization: true,
          roles: ["admin", "store"],
        },
        component: () =>
          import(/* webpackChunkName: "userlist" */ "../views/Users.vue"),
        children: [
          {
            path: "list",
            name: "UserList",
            meta: {
              title: "LİSTE",
              icon: "contact_page",
              requiredAuthorization: true,
              roles: ["admin", "store"],
            },
            component: () =>
              import(/* webpackChunkName: "userlist" */ "../views/UserList.vue"),
          },
          {
            path: ":id",
            name: "UserDetail",
            props: true,
            meta: {
              title: "GÜNCELLE",
              icon: "contact_page",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
            /* webpackChunkName: "userDetail" */ "../views/User.vue"
              ),
          },
          {
            path: "add",
            name: "User",
            props: true,
            meta: {
              title: "EKLE",
              icon: "contact_page",
              requiredAuthorization: true,
              roles: ["admin"],
            },
            component: () =>
              import(
            /* webpackChunkName: "user" */ "../views/User.vue"
              ),
          },
        ]
      },

      {
        path: "/change-password/:id",
        name: "ChangePassword",
        props: true,
        meta: {
          title: "ŞİFRE GÜNCELLE",
          icon: "contact_page",
          requiredAuthorization: true,
          roles: ["admin", "store", "dealer"],
        },
        component: () =>
          import(
            /* webpackChunkName: "password" */ "../views/ChangePassword.vue"
          ),
      },
      {
        path: "/black-list",
        name: "BlackList",
        meta: {
          title: "BLACK LIST",
          icon: "list",
          requiredAuthorization: true,
          roles: ["admin"],
        },
        component: () =>
          import(/* webpackChunkName: "BlackList" */ "../views/BlackList.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/client/login",
    name: "ClientLogin",
    meta: {
      requiredAuthorization: false,
    },
    component: () =>
      import(/* webpackChunkName: "clientlogin" */ "../views/ClientLogin.vue"),
  },
  {
    path: "/client",
    redirect: "/profile",
    meta: {
      title: "ANASAYFA",
      icon: "home",
      requiredAuthorization: true,
      roles: ["customer"],
    },
    component: () =>
      import(/* webpackChunkName: "layout" */ "../components/Layout.vue"),
      children:[
        {
          path: "/client/profile",
          name: "ClientProfile",
          meta: {
            title: "PROFİL",
            icon: "person",
            requiredAuthorization: true,
            roles: ["customer"],
          },
          component: () =>
            import(/* webpackChunkName: "ClientProfile" */ "../views/ClientProfile.vue"),
        },
        {
          path: "/client/transaction-history",
          name: "ClientTransactionHistory",
          meta: {
            title: "İŞLEM GEÇMİŞİM",
            icon: "person_search",
            requiredAuthorization: true,
            roles: ["customer"],
          },
          component: () =>
            import(/* webpackChunkName: "ClientTransactionHistory" */ "../views/ClientTransactionHistory.vue"),
        },
        {
          path: "/client/transaction-detail/:id",
          name: "ClientTransactionDetail",
          meta: {
            title: "İŞLEM GEÇMİŞİM",
            icon: "person_search",
            requiredAuthorization: true,
            roles: ["customer"],
          },
          component: () =>
            import(/* webpackChunkName: "ClientTransactionDetail" */ "../views/ClientTransactionDetail.vue"),
        },
        {
          path: "/client/installments",
          name: "ClientInstallments",
          meta: {
            title: "KREDİ TAKİP",
            icon: "account_balance_wallet",
            requiredAuthorization: true,
            roles: ["admin", "customer"],
          },
          component: () =>
            import(/* webpackChunkName: "clientinstallment" */ "../views/ClientInstallments.vue"),
        },
        {
          path: "/client/installment-pdf",
          name: "ClientInstallmentPdf",
          meta: {
            title: "KREDİ TAKİP",
            icon: "account_balance_wallet",
            requiredAuthorization: true,
            roles: ["admin", "customer"],
      
          },
          component: () =>
            import(/* webpackChunkName: "clientinstallmentpdf" */ "../views/ClientInstallmentPdf.vue"),
        },
        {
          path: "/client/files/:salesId",
          name: "ClientScaned",
          meta: {
            title: "TARANAN EVRAKLAR",
            icon: "account_balance_wallet",
            requiredAuthorization: true,
            roles: ["admin", "customer"],
      
          },
          component: () =>
            import(/* webpackChunkName: "clientinstallmentpdf" */ "../views/ClientScanedFiles.vue"),
        },
        
      ]
  },
 
  {
    path: "/forgat-password",
    name: "ForgatPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgatpassword" */ "../views/ForgatPassword.vue"
      ),
  },
  {
    path: "/notfound",
    name: "Notfound",
    meta: {
      requiredAuthorization: false,
    },
    component: () =>
      import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/notfound",
    meta: {
      requiredAuthorization: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  window.scrollTo(0, 0);
  const user = store.getters["auth/getUserRole"];
  if (to.matched.some((record) => record.meta.requiredAuthorization)) {
    from.name == "QueriedProcessDetail" ? store.state.user.printed = false : ""
    from.name == "PrintedDocuments" ? store.state.user.printed = true : ""
    if (!store.getters["auth/getIsLoggedIn"]) {
      next('/login');
    }
    else {
      if (to.meta.requiredAuthorization) {
        if (to.meta?.roles?.includes(user)) {
          next();
        } else {
          next("/notfound");
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
