import axios from "axios";

import store from '../index.js'

const state = () => ({
    userId:'',
    pinCodeReqStatus:'',
    newPasswordReqStatus:'',
    printed:false
})

const getters = {
    getUserId(state){
        return state.userId
    },
    getPinCodeReqStatus(state){
        return state.pinCodeReqStatus
    },
    getNewPasswordReqStatus(state){
        return state.newPasswordReqStatus
    }
}   

const actions = {

    async resetPasswordPhoneReq({commit}, payload){
        const response = await axios.put('user/forgot', payload)
        .catch((err) => {
            console.log(err)
        })
        if(response && response.data){
            commit('setUserId', response.data.id)
            commit('setResetPasswordReqStatus', 'success')
        }
    },

    async pinCodeReq({commit}, payload){        
        const response = await axios.put('user/forgot_reset/' + store.state.user.userId, payload)
        .catch((err) => {
            console.log(err)
        })

        if(response && response.data){
            commit('setPinCodeReqStatus', 'success')
        }
    },

    async newPasswordReq({commit}, payload){
        const response = await axios.put('user/reset/' + store.state.user.userId, payload)
        .catch((err) => {
            console.log(err)
        })

        if(response && response.data){
            commit('setNewPasswordReqStatus', 'success')
        }
    }

}

const mutations = {
    setUserId(state, payload){
        state.userId = payload
    },
    setPinCodeReqStatus(state, payload){
        state.pinCodeReqStatus = payload
    },
    setNewPasswordReqStatus(state, payload){
        state.newPasswordReqStatus = payload
    }
}

export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}