import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './interceptors'
import vSelect from 'vue-select'
import "bootstrap";
import mask from "vue-the-mask";
import VueExcelXlsx from "vue-excel-xlsx";
import Table from "@/components/Table.vue";
import SuccessModal from "@/components/SuccessModal.vue";
import AlertModal from "@/components/AlertModal.vue";
import ErrorModal from "@/components/ErrorModal.vue";
import ShowFileModal from "@/components/ShowFileModal.vue";
import CollectModal from "@/components/CollectModal.vue";
import ImgCropModal from "@/components/ImgCrop.vue";
import CustomeModal from "@/components/CustomeModal.vue";
import Timer from "@/components/Timer.vue";

import env from './sys.config'
import { tckn, vkn, formatCurrency, moneyTotext, convertToDateToEn } from '@/core/helpers'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

app.config.globalProperties = {
    $axios: axios,
    $env: env,
    $vkn: vkn,
    $tckn: tckn,
    $formatCurrency: formatCurrency,
    $moneyTotext: moneyTotext,
    $convertToDateToEn: convertToDateToEn
}

app.component('v-select', vSelect)
app.component('VueDatePicker', VueDatePicker);
app.component('Table', Table);
app.component('SuccessModal', SuccessModal);
app.component('ErrorModal', ErrorModal);
app.component('ShowFileModal', ShowFileModal);
app.component('CollectModal', CollectModal);
app.component('ImgCropModal', ImgCropModal);
app.component('AlertModal', AlertModal);
app.component('CustomeModal', CustomeModal);
app.component('Timer', Timer);

app.use(router)
app.use(store)
app.use(mask)
app.use(VueExcelXlsx)

app.directive('uppercase', {
    // Bağlanabilirlik işlevi
    mounted(el, binding) {
        el.addEventListener('input', function () {
            // Input değerini büyük harfe çevir
            el.value = el.value.replace(/i/g, 'İ');
            el.value = el.value.toUpperCase();

            // v-model ile bağlı veriyi güncelle
            if (binding.value) {
                binding.value(el.value);
            }
        });
    },
});

app.mount('#app')