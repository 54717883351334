import axios from 'axios';
import env from '../sys.config';
import store from '../store'

axios.interceptors.request.use(
    config => {
        return {
            ...config,
            headers: {
                Authorization: "Bearer " + store.state.auth.authData.access_token,
            }
        };
    },
    error => Promise.reject(error)
);
axios.defaults.baseURL = env.VUE_APP_BASE_URL;

export default axios