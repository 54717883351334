<template>
  <button
    @click="exportExcel"
    type="button"
    class="btn btn-filled-blue"
    v-if="!exportOptinal"
  >
    <span class="material-symbols-outlined mr-5"> download </span>
    EXCEL İNDİR
  </button>
  <div class="form-group" v-else>
    <select
      class="btn-filled-blue form-select pl-40 pr-40"
      v-model="exportType"
    >
      <option value="">SEÇİNİZ</option>
      <option value="summary">ÖZET EXCEL İNDİR</option>
      <option value="detail">DETAY EXCEL İNDİR</option>
    </select>
  </div>
</template>

<script>
import XLSX from "xlsx/xlsx";

export default {
  name: "vue-excel-xlsx",
  data() {
    return {
      exportType: "",
    };
  },
  props: {
    exportOptinal: {
      type: Boolean,
      default: false,
    },
    thead: {
      type: Array,
      default: () => [],
    },
    detailHead: {
      type: Array,
      default: () => [],
    },
    detailData: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "excel",
    },
    sheetName: {
      type: String,
      default: "SheetName",
    },
    fileType: {
      type: String,
      default: "xlsx",
      validator: (val) => ["xlsx", "xls"].includes(val),
    },
  },
  watch: {
    exportType: function () {
      this.exportExcel();
    },
  },
  methods: {
    fitToColumn(arrayOfArray) {
      // get maximum character of each column
      return arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length + 5 : 5))
        ),
      }));
    },
    exportExcel() {
      let createXLSLFormatObj = [];
      let newXlsHeader = [];
      let vm = this;

      let hd, dt;

      if (vm.thead.length === 0) {
        return;
      }
      if (vm.data.length === 0) {
        return;
      }

      vm.exportType == "detail" ? (hd = vm.detailHead) : (hd = vm.thead);
      vm.exportType == "detail" ? (dt = vm.detailData) : (dt = vm.data);

      hd.map((column) => {
        if (column.exports != false) {
          newXlsHeader.push(column.label);
        }
      });

      createXLSLFormatObj.push(newXlsHeader);

      dt.map((value) => {
        let innerRowData = [];
        hd.map((val) => {
          let fieldValue =
            typeof value[val.value] == "object" &&
            value[val.value].type == "currency"
              ? value[val.value].value
              : value[val.value];

          if (val.exports != false) {
            if (val.value.split(".").length > 1) {
              fieldValue = this.getNestedValue(value, val.value);
            }
            if (val.dataFormat && typeof val.dataFormat === "function") {
              innerRowData.push(val.dataFormat(fieldValue));
            } else {
              innerRowData.push(fieldValue);
            }
          }
        });
        createXLSLFormatObj.push(innerRowData);
      });

      let fileName = vm.fileName + "." + vm.fileType;

      let ws_name = vm.sheetName;

      let wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      ws["!cols"] = this.fitToColumn(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, fileName);
    },

    getNestedValue(object, string) {
      string = string.replace(/\[(\w+)\]/g, ".$1");
      string = string.replace(/^\./, "");
      let a = string.split(".");
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }
      return object;
    },
  },
};
</script>
<style scoped lang="scss">
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  text-align: center;
  option {
    color: #000;
  }
}
</style>