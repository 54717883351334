<template>
  <div class="modal-wrapper">
    <div class="modal-container alert-modal">
      <div class="modal-header">{{ fileName }}</div>
      <div class="modal-body">
        <cropper ref="cropper" :src="img" @change="changeCropper" />
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-filled-grey-300 m-auto"
          @click="closeModal"
          type="button"
        >
          KAPAT
        </button>
        <button
          class="btn btn-filled-blue m-auto"
          @click="uploadImage"
          type="button"
        >
          YÜKLE
        </button>
      </div>
    </div>
  </div>
</template>
  <script>
import "vue-advanced-cropper/dist/style.css";
import { Cropper } from "vue-advanced-cropper";
export default {
  data() {
    return {
      isCloseModal: false,
    };
  },
  components: {
    Cropper,
  },
  props: {
    transactionId: {
      type: String,
    },
    fileName: {
      type: String,
    },
    img: {
      type: String,
    },
    fileType: {
      type: String,
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", this.isCloseModal);
    },
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const formData = new FormData();
        canvas.toBlob((blob) => {
          
          let file = new File([blob], `${this.fileType}.jpeg`, {
            type: blob.type,
          });
          formData.append("files", file);

            this.$axios
              .patch("customer/file-upload/" + this.transactionId, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(() => {
                this.$emit("successModal", true);
              });
        }, "image/jpeg");
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.modal-wrapper .modal-container {
  max-width: 700px;
  max-height: 800px;
  .modal-body{
    max-height: 600px;
    overflow-y:auto ;
  }
}
</style>
  